.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* FlipCard.css */

/* CoinFlip.css */

.coin {
  perspective: 1000px;
  width: 200px;
  height: 200px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s;
  cursor: pointer;
}

.coin-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.coin.flipped {
  transform: rotateX(180deg);
}

.coin .coin-inner {
  transform: rotateX(0deg);
}

.coin .coin-front,
.coin .coin-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.coin .coin-front {
  background-color: #f39c12;
  color: #fff;
}

.coin .coin-back {
  background-color: #3498db;
  color: #fff;
  transform: rotateX(180deg);
}

.coin .outcome {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s;
}

.coin.flipped .outcome {
  opacity: 1;
}

.coin .outcome.toss {
  animation: toss 0.8s forwards ease-in-out;
}

@key;
